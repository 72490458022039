/*     Alert    */

.alert {
  border: 0px;
  position: relative;
  padding: .95rem 1.25rem;
  border-radius: 1px;
  color: inherit;
  background-color: $white-color;
  -webkit-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  -moz-box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  box-shadow: 1px 1px 14px 0px rgba(18, 38, 63, 0.26);
  [data-notify="icon"] {
    display: block;
    &::before {
      line-height: 35px;
      font-size: 22px;
      display: block;
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 35px;
      height: 35px;
      border-radius: 30px;
      text-align: center;
      color: #fff;
    }
  }
  [data-notify="title"] {
    display: block;
    color: #2b2b2b;
    font-weight: $font-weight-extrabold;
    font-size: 14px;
    margin-bottom: 5px;
  }
  [data-notify="message"] {
    font-size: 13px;
    color: #908e8e;
  }
  .close {
    background: rgba(255, 255, 255, 0.8);
    width: 25px;
    height: 25px;
    line-height: 25px;
    top: 12px !important;
    border-radius: 50%;
  }
}

/*    Alert States    */

@mixin alert-state($color){
  border-left: 4px solid $color;
  [data-notify="icon"]:before {
    background: $color;
  }
}

.alert-default {
  @include alert-state($dark-color);
}

.alert-primary {
  @include alert-state($primary-color);
}

.alert-secondary {
  @include alert-state($secondary-color);
}

.alert-info {
  @include alert-state($info-color);
}

.alert-success {
  @include alert-state($success-color);
}

.alert-warning {
  @include alert-state($warning-color);
}

.alert-danger {
  @include alert-state($danger-color);
}