.selectgroup {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.selectgroup-item {
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  font-weight: $font-weight-normal !important;
  + .selectgroup-item {
    margin-left: -1px;
  }
  &:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.selectgroup-button {
  display: block;
  border: 1px solid rgba(0, 40, 100, 0.12);
  text-align: center;
  padding: 0.375rem 1rem;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  color: #9aa0ac;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 1.5rem;
  min-width: 2.375rem;
}

.selectgroup-button-icon {
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem;
}

.selectgroup-input {
  &:checked + .selectgroup-button {
    border-color: $primary-color;
    z-index: 1;
    color: $primary-color;
    background: rgba($primary-color, .15);
  }
  &:focus + .selectgroup-button {
    border-color: $primary-color;
    z-index: 2;
    color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, .25);
  }
}

.selectgroup-pills {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  .selectgroup-item {
    margin-right: .5rem;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .selectgroup-button {
    border-radius: 50px !important;
  }
}

@mixin select-group-state-style($color) {
  .selectgroup-input {
    &:checked + .selectgroup-button {
      border-color: $color;
      color: $color;
      background: rgba($color, .15);
    }
    &:focus + .selectgroup-button {
      border-color: $color;
      color: $color;
      box-shadow: 0 0 0 2px rgba($color, .25);
    }
  }
}

.selectgroup {
  &.selectgroup-primary {
    @include select-group-state-style($primary-color);
  }
  &.selectgroup-secondary {
    @include select-group-state-style($secondary-color);
  }
  &.selectgroup-info {
    @include select-group-state-style($info-color);
  }
  &.selectgroup-success {
    @include select-group-state-style($success-color);
  }
  &.selectgroup-warning {
    @include select-group-state-style($warning-color);
  }
  &.selectgroup-danger {
    @include select-group-state-style($danger-color);
  }
}

