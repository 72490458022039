/*     Responsive     */
@media screen and (max-width: 576px) {
  .row-card-no-pd {
    [class*=col-] { 
      .card {
        &:before {
          width: calc(100% - 30px) !important;
          right: 15px !important;
          height: 1px !important;
        }
      }
      &:first-child {
        .card {
          &:before {
            display: none !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .main-panel {
    &.full-height {
      > .content, > .content-full{
        margin-top: 0;
      }
      .navbar-header {
        min-height: 62px;
      }
    }
  }
  .logo-header {
    line-height: 57px;
  }
  .toggle-nav-search {
    display: none;
  }
  #search-nav {
    display: block !important;
  }
  .sidebar {
    .scroll-element {
      opacity: 0;
      transition: all .2s;
    }
    &:hover .scroll-element {
      opacity: 1;
    }
  }
  .sidebar[data-background-color] {
    &:before {
      background: rgba(255, 255, 255, 0.2) !important;
      z-index: 1000;
    }
  }
}

@media screen and (max-width: 991px) {
  .main-header[data-background-color] {
    .navbar-header {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .sidebar {
    position: fixed;
    left: 0 !important;
    right: 0;
    -webkit-transform: translate3d(-270px, 0, 0);
    -moz-transform: translate3d(-270px, 0, 0);
    -o-transform: translate3d(-270px, 0, 0);
    -ms-transform: translate3d(-270px, 0, 0);
    transform: translate3d(-270px, 0, 0) !important;
    transition: all .5s;
    margin-top: 0px;
    &:before {
      background: none;
    }
  }
  .nav_open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) !important;
    border-right: 1px solid $grey-color;
  }
  .sidebar .sidebar-wrapper {
    padding-top: 0px;
    .sidebar-content {
      padding-top: 0px !important;
    }
    .scroll-element.scroll-y {
      top: 0px !important;
    }
  }
  .nav_open {
    overflow: hidden !important;
    .wrapper {
      overflow-x: hidden;
    }
    .main-panel, .main-header {
      -webkit-transform: translate3d(250px, 0, 0);
      -moz-transform: translate3d(250px, 0, 0);
      -o-transform: translate3d(250px, 0, 0);
      -ms-transform: translate3d(250px, 0, 0);
      transform: translate3d(250px, 0, 0) !important;
    }
  }
  .quick_sidebar_open .quick-sidebar {
    width: 350px;
  }
  .main-header {
    transition: all .5s;
  }
  #search-nav {
    margin: 0 auto !important;
  }
  .main-panel {
    width: 100%;
    transition: all .5s;
    .page-header {
      .dropdown-menu {
        &:after {
          right: 16px !important;
        }
      }
    }
  }
  .page-inner {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .page-sidebar {
    background: #fff;
  }
  .logo-header {
    display: flex;
    width: 100% !important;
    text-align: left;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .navbar-toggler {
      height: 100%;
      margin-left: 0px !important;
      opacity: 1;
      display: block;
      order: 1;
    }
    .more {
      opacity: 1;
      color: #545454;
      cursor: pointer;
      display: inline-block;
      line-height: 56px;
      order: 3;
      width: unset;
      margin-left: auto;
    }
    .navbar-brand {
      position: unset !important;
      margin-right: 0px;
    }
  }
  .nav-search {
    width: 100%;
    margin-right: 0 !important;
  }
  .navbar-header {
    position: absolute;
    width: 100%;
    transform: translate3d(0, -200px, 0) !important;
    transition: all .5s;
  }
  .topbar_open {
    .navbar-header {
      transform: translate3d(0, 61px, 0) !important;
      padding: 6px;
      .navbar-nav {
        > .nav-item {
          .nav-link {
            i {
              font-size: 19px;
            }
          }
          &:last-child {
            .nav-link {
              padding: 0px !important;
            }
            .quick-sidebar-toggler {
              padding-left: 5px !important;
            }
          }
        }
      }
    }
    .toggle-nav-search {
      display: list-item;
    }
    #search-nav {
      text-align: center;
      width: 100%;
      padding: 10px 15px 0px;
      order: 1;
    }
    .main-panel {
      transform: translate3d(0, 62px, 0) !important;
    }
    > .content {
      margin-top: 0px !important;
    }
  }
  .nav_open.topbar_open .main-panel {
    transform: translate3d(250px, 60px, 0) !important;
  }
  .navbar-header .navbar-nav {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-left: 0px !important;
    position: relative;
    .dropdown {
      position: unset;
    }
    .dropdown-menu {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 280px;
    }
  }
  .profile-pic span {
    display: none;
  }
  .nav-toggle{
    display: none;
  }
  .page-title{
    font-size: 18px;
  }
  .card {
    .card-title {
      font-size: 18px;
    }
  }
  .mail-wrapper {
    .mail-option {
      .email-filters-left {
        width: 50%;
        .btn-group {
          margin-bottom: 10px;
        }
      }
    }
  }

  /* Dropzone */
  .dropzone {
    padding: 20px 15px !important;
    .dz-message {
      .message {
        font-size: 23px;
      }
      .note {
        font-size: 15px;
      }
    }
  }
}

@media screen and (min-width: 856px) {
  .mail-wrapper {
    .aside-nav {
      display: block !important;
    }
  }
}
@media screen and (max-width: 856px) {
  .mail-wrapper {
    flex-direction: column;
    .page-aside {
      width: 100%;
      height: unset;
      min-height: unset;
      border-bottom: 1px solid #eee;
      border-left: 0;
      border-right: 0;
      background: $transparent-bg;
      padding-top: 0px;
      padding-bottom: 0px;
      .aside-header {
        padding-top: 25px;
        padding-bottom: 25px;
        background: $grey-color;
      }
      .aside-nav {
        background: $white-color;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    .mail-content {
      width: 100%;
      .inbox-head{
        flex-direction: column;
        align-items: left;
        h3 {
          font-size: 18px;
        }
        form {
          margin-left: 0px !important;
          margin-top: 15px;
        }
      }
      .email-head{
        h3 {
          font-size: 18px;
        }
      }
      .email-compose-fields {
        padding: 20px 15px;
      }
    }
    .mail-option {
      flex-direction: column;
      .email-filters-left {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .toggle-email-nav {
     display: inline-block !important;
   }
   .table-inbox {
    tr {
      td {
        .badge {
          margin-top: 5px;
          float: left;
        }
      }
    }
  }
}
}

@media screen and (max-width: 767px){
  .wizard-container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .main-panel {
    .page-header {
      flex-direction: column;
      align-items: normal;
      position: relative;
      min-height: 43px;
      justify-content: center;
      .breadcrumbs {
        margin-left: 0px;
        padding-top: 15px;
        padding-left: 5px;
        padding-bottom: 0px;
        border-left: 0px;
      }
      .btn-group-page-header{
        position: absolute;
        right: 0px;
      }
    }
  }
  .footer .container-fluid{
    flex-direction: column;
    .copyright {
      margin-left: 0 !important;
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 576px){
  #chart-container {
    min-height: 250px;
  }

  .form-check-inline {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  #calendar {
    .fc-toolbar {
      display: flex;
      flex-direction: column;
      %fc-position-margin {
        margin: auto;
        margin-bottom: 15px;
      }
      .fc-left {
        @extend %fc-position-margin;
        order: 1;
      }
      .fc-right {
        @extend %fc-position-margin;
        order: 3;
      }
      .fc-center {
        @extend %fc-position-margin;
        order: 2;
      }
    }
  }

  .conversations {
    .conversations-body {
      padding: 1.5rem 1rem;
    }
  }
}

@media screen and (max-width: 350px){
  .quick_sidebar_open .quick-sidebar {
    width: 100%;
    padding: 20px;
  }
}

