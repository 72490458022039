.gutters-0 {
  margin-right: 0;
  margin-left: 0;
  > {
    .col, [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .card {
    margin-bottom: 0;
  }
}

.gutters-xs {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
  > {
    .col, [class*="col-"] {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
    }
  }
  .card {
    margin-bottom: 0.5rem;
  }
}

.gutters-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  > {
    .col, [class*="col-"] {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .card {
    margin-bottom: 1rem;
  }
}

.gutters-lg {
  margin-right: -1rem;
  margin-left: -1rem;
  > {
    .col, [class*="col-"] {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  .card {
    margin-bottom: 2rem;
  }
}

.gutters-xl {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  > {
    .col, [class*="col-"] {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
  .card {
    margin-bottom: 3rem;
  }
}