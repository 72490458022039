/*      jQuery Ui     */
.ui-draggable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix {
  &:after, &:before {
    content: "";
    display: table;
    border-collapse: collapse;
  }
  &:after {
    clear: both;
  }
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity = 0);
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-resizable {
  position: relative;
}

.ui-resizable-handle {
  position: absolute;
  font-size: .1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}

.ui-resizable-autohide .ui-resizable-handle, .ui-resizable-disabled .ui-resizable-handle {
  display: none;
}

.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}

.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}

.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}

.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}

.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}

.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}

.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}

.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted #000;
}

.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}

.ui-slider {
  position: relative;
  text-align: left;
  background: #ddd;
  .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none;
  }
  .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
  }
  &.ui-state-disabled {
    .ui-slider-handle, .ui-slider-range {
      filter: inherit;
    }
  }
}

.ui-slider-horizontal {
  height: .4em;
  .ui-slider-handle {
    top: -.4em;
    margin-left: -.6em;
  }
  .ui-slider-range {
    top: 0;
    height: 100%;
  }
  .ui-slider-range-min {
    left: 0;
  }
  .ui-slider-range-max {
    right: 0;
  }
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
  .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
  }
  .ui-slider-range {
    left: 0;
    width: 100%;
  }
  .ui-slider-range-min {
    bottom: 0;
  }
  .ui-slider-range-max {
    top: 0;
  }
}

.ui-slider-handle {
  background: $white-color;
  background: -moz-linear-gradient(top, $white-color 0, #f7f7f7 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $white-color), color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, $white-color 0, #f7f7f7 100%);
  background: -o-linear-gradient(top, $white-color 0, #f7f7f7 100%);
  background: -ms-linear-gradient(top, $white-color 0, #f7f7f7 100%);
  background: linear-gradient(to bottom, $white-color 0, #f7f7f7 100%);
  border-radius: 50px;
  box-shadow: 0 1px 4px 0 #9191ab !important;
  &:focus {
    background: $white-color;
    background: -moz-linear-gradient(top, $white-color 0, #f7f7f7 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, $white-color), color-stop(100%, #f7f7f7));
    background: -webkit-linear-gradient(top, $white-color 0, #f7f7f7 100%);
    background: -o-linear-gradient(top, $white-color 0, #f7f7f7 100%);
    background: -ms-linear-gradient(top, $white-color 0, #f7f7f7 100%);
    background: linear-gradient(to bottom, $white-color 0, #f7f7f7 100%);
    border-radius: 50px;
    box-shadow: 0 1px 4px 0 #9191ab !important;
  }
}