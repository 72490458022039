//## For each of Bootstrap's buttons, define text, background and border color.

$body-text-color: #575962;
$white-color: #ffffff;
$grey-color: #f1f1f1;
$grey2-color: #f9fbfd;
$black-color: #191919;
$transparent-bg : transparent;
$dark-color : #1a2035;
$dark2-color : #1f283e;
$primary-color : #1572E8;
$primary2-color : #1269DB;
$secondary-color : #6861CE;
$secondary2-color : #5C55BF;
$info-color : #48ABF7;
$info2-color : #3697E1;
$success-color : #31CE36;
$success2-color : #2BB930;
$warning-color : #FFAD46;
$warning2-color : #FF9E27;
$danger-color : #F25961;
$danger2-color : #EA4d56;

// Font Weight
$font-weight-light : 300;
$font-weight-normal : 400;
$font-weight-bold : 600;
$font-weight-extrabold : 700;