/*      jQuery Scrollbar     */
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
  > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
}

.scroll-element {
  display: none;
  box-sizing: content-box;
  div {
    box-sizing: content-box;
  }
  &.scroll-x.scroll-scrollx_visible, &.scroll-y.scroll-scrolly_visible {
    display: block;
  }
  .scroll-arrow, .scroll-bar {
    cursor: default;
  }
}

.scroll-textarea {
  border: 1px solid #ccc;
  border-top-color: #999;
  > .scroll-content {
    overflow: hidden !important;
    > textarea {
      border: none !important;
      box-sizing: border-box;
      height: 100% !important;
      margin: 0;
      max-height: none !important;
      max-width: none !important;
      overflow: scroll !important;
      outline: 0;
      padding: 2px;
      position: relative !important;
      top: 0;
      width: 100% !important;
    }
  }
}

.scrollbar-inner > .scroll-element .scroll-element_outer, .scrollbar-outer > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scrollbar-inner > .scroll-element {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  div {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  &.scroll-x {
    bottom: 2px;
    height: 7px;
    left: 0;
    width: 100%;
    &.scroll-scrolly_visible {
      .scroll-element_size, .scroll-element_track {
        left: -12px;
      }
    }
  }
  &.scroll-y {
    height: 100%;
    right: 2px;
    top: 0;
    width: 7px;
    &.scroll-scrollx_visible {
      .scroll-element_size, .scroll-element_track {
        top: -12px;
      }
    }
  }
  .scroll-bar, .scroll-element_outer, .scroll-element_track {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .scroll-bar {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity = 40);
    opacity: .4;
  }
  .scroll-element_track {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity = 40);
    opacity: .4;
    background-color: #e0e0e0;
  }
  .scroll-bar {
    background-color: #c2c2c2;
  }
  &.scroll-draggable .scroll-bar, &:hover .scroll-bar {
    background-color: #919191;
  }
}

.scrollbar-outer > {
  .scroll-element {
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    div {
      border: none;
      margin: 0;
      padding: 0;
      position: absolute;
      z-index: 10;
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
    background-color: $white-color;
    &.scroll-x {
      bottom: 0;
      height: 12px;
      left: 0;
      width: 100%;
    }
    &.scroll-y {
      height: 100%;
      right: 0;
      top: 0;
      width: 12px;
    }
    &.scroll-x .scroll-element_outer {
      height: 7px;
      top: 2px;
    }
    &.scroll-y .scroll-element_outer {
      left: 2px;
      width: 7px;
    }
    .scroll-element_track {
      background-color: #eee;
    }
    .scroll-bar, .scroll-element_outer, .scroll-element_track {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
    .scroll-bar {
      background-color: #d9d9d9;
      &:hover {
        background-color: #c2c2c2;
      }
    }
    &.scroll-draggable .scroll-bar {
      background-color: #919191;
    }
  }
  .scroll-content.scroll-scrolly_visible {
    left: -12px;
    margin-left: 12px;
  }
  .scroll-element.scroll-x.scroll-scrolly_visible {
    .scroll-element_size, .scroll-element_track {
      left: -14px;
    }
  }
  .scroll-content.scroll-scrollx_visible {
    top: -12px;
    margin-top: 12px;
  }
  .scroll-element {
    &.scroll-y.scroll-scrollx_visible {
      .scroll-element_size, .scroll-element_track {
        top: -14px;
      }
    }
    &.scroll-x .scroll-bar {
      min-width: 10px;
    }
    &.scroll-y .scroll-bar {
      min-height: 10px;
    }
  }
}

.scrollbar-macosx {
  > .scroll-element {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    div {
      background: 0 0;
      border: none;
      margin: 0;
      padding: 0;
      position: absolute;
      z-index: 10;
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
    .scroll-element_track {
      display: none;
    }
    .scroll-bar {
      background-color: #6C6E71;
      display: block;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity = 0);
      opacity: 0;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      -webkit-transition: opacity .2s linear;
      -moz-transition: opacity .2s linear;
      -o-transition: opacity .2s linear;
      -ms-transition: opacity .2s linear;
      transition: opacity .2s linear;
    }
  }
  &:hover > .scroll-element .scroll-bar {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity = 70);
    opacity: .7;
  }
  > .scroll-element {
    &.scroll-draggable .scroll-bar {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      filter: alpha(opacity = 70);
      opacity: .7;
    }
    &.scroll-x {
      bottom: 0;
      height: 0;
      left: 0;
      min-width: 100%;
      overflow: visible;
      width: 100%;
    }
    &.scroll-y {
      height: 100%;
      min-height: 100%;
      right: 0;
      top: 0;
      width: 0;
    }
    &.scroll-x .scroll-bar {
      height: 7px;
      min-width: 10px;
      top: -9px;
    }
    &.scroll-y .scroll-bar {
      left: -9px;
      min-height: 10px;
      width: 7px;
    }
    &.scroll-x {
      .scroll-element_outer {
        left: 2px;
      }
      .scroll-element_size {
        left: -4px;
      }
    }
    &.scroll-y {
      .scroll-element_outer {
        top: 2px;
      }
      .scroll-element_size {
        top: -4px;
      }
    }
    &.scroll-x.scroll-scrolly_visible .scroll-element_size {
      left: -11px;
    }
    &.scroll-y.scroll-scrollx_visible .scroll-element_size {
      top: -11px;
    }
  }
}

.scrollbar-light > {
  .scroll-element {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
    div {
      border: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      z-index: 10;
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
    background-color: $white-color;
    .scroll-element_outer {
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    .scroll-element_size {
      background: -moz-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0, #dbdbdb), color-stop(100%, #e8e8e8));
      background: -webkit-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
      background: -o-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
      background: -ms-linear-gradient(left, #dbdbdb 0, #e8e8e8 100%);
      background: linear-gradient(to right, #dbdbdb 0, #e8e8e8 100%);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
    &.scroll-x {
      bottom: 0;
      height: 17px;
      left: 0;
      min-width: 100%;
      width: 100%;
    }
    &.scroll-y {
      height: 100%;
      min-height: 100%;
      right: 0;
      top: 0;
      width: 17px;
    }
    .scroll-bar {
      background: -moz-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0, #fefefe), color-stop(100%, #f5f5f5));
      background: -webkit-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
      background: -o-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
      background: -ms-linear-gradient(left, #fefefe 0, #f5f5f5 100%);
      background: linear-gradient(to right, #fefefe 0, #f5f5f5 100%);
      border: 1px solid #dbdbdb;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      border-radius: 10px;
    }
  }
  .scroll-content {
    &.scroll-scrolly_visible {
      left: -17px;
      margin-left: 17px;
    }
    &.scroll-scrollx_visible {
      top: -17px;
      margin-top: 17px;
    }
  }
  .scroll-element {
    &.scroll-x .scroll-bar {
      height: 10px;
      min-width: 10px;
      top: 0;
    }
    &.scroll-y .scroll-bar {
      left: 0;
      min-height: 10px;
      width: 10px;
    }
    &.scroll-x {
      .scroll-element_outer {
        height: 12px;
        left: 2px;
        top: 2px;
      }
      .scroll-element_size {
        left: -4px;
      }
    }
    &.scroll-y .scroll-element_outer {
      left: 2px;
      top: 2px;
      width: 12px;
    }
    &.scroll-x.scroll-scrolly_visible {
      .scroll-element_size, .scroll-element_track {
        left: -19px;
      }
    }
    &.scroll-y {
      .scroll-element_size {
        top: -4px;
      }
      &.scroll-scrollx_visible {
        .scroll-element_size, .scroll-element_track {
          top: -19px;
        }
      }
    }
  }
}

.scrollbar-rail > {
  .scroll-element {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
    div {
      border: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      z-index: 10;
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
    background-color: $white-color;
    .scroll-element_size {
      background-color: #999;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .scroll-element_outer:hover .scroll-element_size {
      background-color: #666;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &.scroll-x {
      bottom: 0;
      height: 12px;
      left: 0;
      min-width: 100%;
      padding: 3px 0 2px;
      width: 100%;
    }
    &.scroll-y {
      height: 100%;
      min-height: 100%;
      padding: 0 2px 0 3px;
      right: 0;
      top: 0;
      width: 12px;
    }
    .scroll-bar {
      background-color: #d0b9a0;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
    .scroll-element_outer:hover .scroll-bar {
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
    }
  }
  .scroll-content {
    &.scroll-scrolly_visible {
      left: -17px;
      margin-left: 17px;
    }
    &.scroll-scrollx_visible {
      margin-top: 17px;
      top: -17px;
    }
  }
  .scroll-element {
    &.scroll-x .scroll-bar {
      height: 10px;
      min-width: 10px;
      top: 1px;
    }
    &.scroll-y .scroll-bar {
      left: 1px;
      min-height: 10px;
      width: 10px;
    }
    &.scroll-x {
      .scroll-element_outer {
        height: 15px;
        left: 5px;
      }
      .scroll-element_size {
        height: 2px;
        left: -10px;
        top: 5px;
      }
    }
    &.scroll-y {
      .scroll-element_outer {
        top: 5px;
        width: 15px;
      }
      .scroll-element_size {
        left: 5px;
        top: -10px;
        width: 2px;
      }
    }
    &.scroll-x.scroll-scrolly_visible {
      .scroll-element_size, .scroll-element_track {
        left: -25px;
      }
    }
    &.scroll-y.scroll-scrollx_visible {
      .scroll-element_size, .scroll-element_track {
        top: -25px;
      }
    }
  }
}

.scrollbar-dynamic > .scroll-element {
  background: 0 0;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  div {
    background: 0 0;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  &.scroll-x {
    bottom: 2px;
    height: 7px;
    left: 0;
    min-width: 100%;
    width: 100%;
  }
  &.scroll-y {
    height: 100%;
    min-height: 100%;
    right: 2px;
    top: 0;
    width: 7px;
  }
  .scroll-element_outer {
    opacity: .3;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
  }
  .scroll-element_size {
    background-color: #ccc;
    opacity: 0;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -webkit-transition: opacity .2s;
    -moz-transition: opacity .2s;
    -o-transition: opacity .2s;
    -ms-transition: opacity .2s;
    transition: opacity .2s;
  }
  .scroll-bar {
    background-color: #6c6e71;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
  }
  &.scroll-x .scroll-bar {
    bottom: 0;
    height: 7px;
    min-width: 24px;
    top: auto;
  }
  &.scroll-y .scroll-bar {
    left: auto;
    min-height: 24px;
    right: 0;
    width: 7px;
  }
  &.scroll-x .scroll-element_outer {
    bottom: 0;
    top: auto;
    left: 2px;
    -webkit-transition: height .2s;
    -moz-transition: height .2s;
    -o-transition: height .2s;
    -ms-transition: height .2s;
    transition: height .2s;
  }
  &.scroll-y .scroll-element_outer {
    left: auto;
    right: 0;
    top: 2px;
    -webkit-transition: width .2s;
    -moz-transition: width .2s;
    -o-transition: width .2s;
    -ms-transition: width .2s;
    transition: width .2s;
  }
  &.scroll-x .scroll-element_size {
    left: -4px;
  }
  &.scroll-y .scroll-element_size {
    top: -4px;
  }
  &.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -11px;
  }
  &.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -11px;
  }
  &.scroll-draggable .scroll-element_outer, &:hover .scroll-element_outer {
    overflow: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity = 70);
    opacity: .7;
  }
  &.scroll-draggable .scroll-element_outer .scroll-element_size, &:hover .scroll-element_outer .scroll-element_size {
    opacity: 1;
  }
  &.scroll-draggable .scroll-element_outer .scroll-bar, &:hover .scroll-element_outer .scroll-bar {
    height: 100%;
    width: 100%;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
  }
  &.scroll-x {
    &.scroll-draggable .scroll-element_outer, &:hover .scroll-element_outer {
      height: 20px;
      min-height: 7px;
    }
  }
  &.scroll-y {
    &.scroll-draggable .scroll-element_outer, &:hover .scroll-element_outer {
      min-width: 7px;
      width: 20px;
    }
  }
}

.scrollbar-chrome > {
  .scroll-element {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
    div {
      border: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      z-index: 10;
      display: block;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
    background-color: $white-color;
    .scroll-element_track {
      background: $grey-color;
      border: 1px solid #dbdbdb;
    }
    &.scroll-x {
      bottom: 0;
      height: 16px;
      left: 0;
      min-width: 100%;
      width: 100%;
    }
    &.scroll-y {
      height: 100%;
      min-height: 100%;
      right: 0;
      top: 0;
      width: 16px;
    }
    .scroll-bar {
      background-color: #d9d9d9;
      border: 1px solid #bdbdbd;
      cursor: default;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      &:hover {
        background-color: #c2c2c2;
        border-color: #a9a9a9;
      }
    }
    &.scroll-draggable .scroll-bar {
      background-color: #919191;
      border-color: #7e7e7e;
    }
  }
  .scroll-content {
    &.scroll-scrolly_visible {
      left: -16px;
      margin-left: 16px;
    }
    &.scroll-scrollx_visible {
      top: -16px;
      margin-top: 16px;
    }
  }
  .scroll-element {
    &.scroll-x .scroll-bar {
      height: 5px;
      min-width: 10px;
      top: 3px;
    }
    &.scroll-y .scroll-bar {
      left: 3px;
      min-height: 10px;
      width: 5px;
    }
    &.scroll-x {
      .scroll-element_outer {
        border-left: 1px solid #dbdbdb;
      }
      .scroll-element_track {
        height: 14px;
        left: -3px;
      }
      .scroll-element_size {
        height: 14px;
        left: -4px;
      }
      &.scroll-scrolly_visible {
        .scroll-element_size, .scroll-element_track {
          left: -19px;
        }
      }
    }
    &.scroll-y {
      .scroll-element_outer {
        border-top: 1px solid #dbdbdb;
      }
      .scroll-element_track {
        top: -3px;
        width: 14px;
      }
      .scroll-element_size {
        top: -4px;
        width: 14px;
      }
      &.scroll-scrollx_visible {
        .scroll-element_size, .scroll-element_track {
          top: -19px;
        }
      }
    }
  }
}