.imagecheck {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.imagecheck-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.imagecheck-figure {
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  margin: 0;
  position: relative;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure {
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
  }
  &:checked ~ .imagecheck-figure {
    border-color: rgba(0, 40, 100, 0.24);
  }
}

.imagecheck-figure:before {
  content: '';
  position: absolute;
  top: .25rem;
  left: .25rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: $primary-color url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center (center / 50%) 50%;
  color: #fff;
  z-index: 1;
  border-radius: 3px;
  opacity: 0;
  transition: .3s opacity;
}

.imagecheck-input:checked ~ .imagecheck-figure:before {
  opacity: 1;
}

.imagecheck-image {
  max-width: 100%;
  opacity: .64;
  transition: .3s opacity;
  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.imagecheck:hover .imagecheck-image {
  opacity: 1;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure .imagecheck-image, &:checked ~ .imagecheck-figure .imagecheck-image {
    opacity: 1;
  }
}

.imagecheck-caption {
  text-align: center;
  padding: .25rem .25rem;
  color: #9aa0ac;
  font-size: 0.875rem;
  transition: .3s color;
}

.imagecheck:hover .imagecheck-caption {
  color: #495057;
}

.imagecheck-input {
  &:focus ~ .imagecheck-figure .imagecheck-caption, &:checked ~ .imagecheck-figure .imagecheck-caption {
    color: #495057;
  }
}