
.Eclispedot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  width: 150px;
}



#candidateProfiletable th {
  min-width: 120px !important;
}


.rowCount {
  width: fit-content;
  float: "left";
  text-align: center;
  margin-top: 30px;
  display: inline-block;
  padding: 0 0 0 40px;
}

.rowCount span {
  color: black;
  display: flex;
  flex-wrap: wrap;
  background: #dbdce3;
  padding: 20px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgb(0 0 0 / 10%);
  height: 64px;
}


.pagination {
  width: fit-content !important;
  float: right;
  padding: 0 40px 0 0;
  margin-bottom: 21px;
}

.pagination ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #dbdce3;
  padding: 8px;
  border-radius: 50px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.pagination ul li {
  color: black;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}

.pagination ul li.numb {
  list-style: none;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  line-height: 45px;
  border-radius: 50%;
}

.pagination ul li.numb.first {
  margin: 0px 3px 0 -5px;
}

.pagination ul li.numb.last {
  margin: 0px -5px 0 3px;
}

.pagination ul li.dots {
  font-size: 22px;
  cursor: default;
}

.pagination ul li.btn {
  padding: 0 20px;
  border-radius: 50px;
}

.pagination li.active,
.pagination ul li.numb:hover,
.pagination ul li:first-child:hover,
.pagination ul li:last-child:hover {
  color: #fff;
  background: #67ae67;
}

.ReactToolTip {
  width: 300px;

}

.hide {
  display: none;
}

.pointer {
  pointer-events: none;
  opacity: 0.3;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;

}

.main-panel > .content {
  
  background: #f9fbfd;

}
#AddBulkUpload{
  padding-right: 0 !important;
}
#AddBulkUpload table{
  width: 100%;
}



#FileDataTable td {
  border: 1px solid black !important;
  border-collapse: collapse;
  padding:  0 !important;
}

datalist {
  position: absolute;
  max-height: 20em;
  border: 0 none;
  overflow-x: hidden;
  overflow-y: auto;
}

datalist option {
  font-size: 0.8em;
  padding: 0.3em 1em;
  background-color: #ccc;
  cursor: pointer;
}

datalist option:hover, datalist option:focus {
  color: #fff;
  background-color: #036;
  outline: 0 none;
}

.None {
  pointer-events:  none;
}

.TextRed{
  color: red;
}

.Textgreen{
  color: green;
}

#FileDataTable .TableTr  td {
  text-align: center !important;
  font-weight: 600;
}

table.dataTable tr th.select-checkbox.selected::after {
  content: "✔";
  margin-top: -11px;
  margin-left: -4px;
  text-align: center;
  text-shadow: rgb(176, 190, 217) 1px 1px, rgb(176, 190, 217) -1px -1px, rgb(176, 190, 217) 1px -1px, rgb(176, 190, 217) -1px 1px;
}
#DemandTable th:first-child,#DemandTable tbody tr.odd td:first-child, #DemandTable tbody tr.odd  td:nth-child(2),#DemandTable tbody tr.odd  td:last-child {
  background-color:#e6e6e6 !important;
 } 
 
 
 #DemandTable tbody tr.even td:first-child, #DemandTable tbody tr.even  td:nth-child(2), #DemandTable tbody tr.even  td:last-child {
   background-color:#fff !important;
  } 

  #ProfileSearchTable th:first-child,#ProfileSearchTable tbody tr.odd td:first-child, #ProfileSearchTable tbody tr.odd  td:nth-child(2),#ProfileSearchTable tbody tr.odd  td:nth-child(3),#ProfileSearchTable tbody tr.odd  td:last-child {
    background-color:#e6e6e6 !important;
   } 
   
   
   #ProfileSearchTable tbody tr.even td:first-child, #ProfileSearchTable tbody tr.even  td:nth-child(2),#ProfileSearchTable tbody tr.even  td:nth-child(3), #ProfileSearchTable tbody tr.even  td:last-child {
     background-color:#fff !important;
    } 
.dtfc-fixed-left{
    background: white !important;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 999;
  }

  #candidateProfiletable th:first-child,#candidateProfiletable tbody tr.odd td:first-child, #candidateProfiletable tbody tr.odd  td:nth-child(2),#candidateProfiletable tbody tr.odd  td:last-child {
    background-color:#e6e6e6 !important;
   } 
   
   
   #candidateProfiletable tbody tr.even td:first-child, #candidateProfiletable tbody tr.even  td:nth-child(2), #candidateProfiletable tbody tr.even  td:last-child {
     background-color:#fff !important;
    } 


/* yearformwidth */
.formwidth{
  width: auto !important; 
}

  

 