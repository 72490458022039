/* 		Opacity  	*/

.op-9 {
opacity: 0.9; 
}

.op-8 {
opacity: 0.8; 
}

.op-7 {
opacity: 0.7; 
}

.op-6 {
opacity: 0.6; 
}

.op-5 {
opacity: 0.5; 
}

.op-4 {
opacity: 0.4; 
}

.op-3 {
opacity: 0.3; 
}
