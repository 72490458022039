/*     Badge    */

.badge {
  border-radius: 50px;
  margin-left: auto;
  line-height: 1;
  padding: 6px 10px;
  vertical-align: middle;
  font-weight: $font-weight-normal;
  font-size: 11px;
  border: 1px solid #ddd;
}

[class*="badge-"]:not(.badge-count) {
  border: 0px !important;
}

.badge-default {
  background: $dark-color;
  color: $white-color !important;
}

.badge-primary {
  background: $primary-color;
}

.badge-secondary {
  background: $secondary-color;
}

.badge-info {
  background: $info-color;
}

.badge-success {
  background-color: $success-color;
}

.badge-warning {
  background: $warning-color;
  color: $white-color !important;
}

.badge-danger {
  background-color: $danger-color;
}